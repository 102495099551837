<template>
  <v-container>
    <h3>{{ $t("message.edit-shop-list-title") }}</h3>
    <div>
      {{ errorMsg }}
    </div>
    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <form @submit.prevent="updateShop">
          <v-row>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.store-name") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                required
                v-model="shopEdit.store_name"
                :error-messages="storeErrors"
                @input="$v.shopEdit.store_name.$touch()"
                @blur="$v.shopEdit.store_name.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.postal-code") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-row align="center">
                <v-col cols="8">
                  <v-text-field
                    outlined
                    required
                    v-model="shopEdit.postal_code"
                    :error-messages="postal_codeErrors"
                    @input="$v.shopEdit.postal_code.$touch()"
                    @blur="$v.shopEdit.postal_code.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col class="mb-8">
                  <v-btn
                    depressed
                    outlined
                    @click.prevent="getAddress()"
                    :disabled="isPostal"
                  >
                    <span>
                      {{ $t("message.search") }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.prefecture") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                v-model="shopEdit.prefecture"
                :error-messages="prefectureErrors"
                @input="$v.shopEdit.prefecture.$touch()"
                @blur="$v.shopEdit.prefecture.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.city") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field
                outlined
                class="input-class"
                required
                v-model="shopEdit.city"
                :error-messages="cityErrors"
                @input="$v.shopEdit.city.$touch()"
                @blur="$v.shopEdit.city.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.district") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field
                outlined
                class="input-class"
                required
                v-model="shopEdit.district"
                :error-messages="districtErrors"
                @input="$v.shopEdit.district.$touch()"
                @blur="$v.shopEdit.district.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.address") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field
                outlined
                class="input-class"
                required
                v-model="shopEdit.store_address"
                :error-messages="addressErrors"
                @input="$v.shopEdit.store_address.$touch()"
                @blur="$v.shopEdit.store_address.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.phone") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field 
                outlined
                v-model="shopEdit.phone"
                class="input-class"
                :error-messages="phoneErrors"
                @input="$v.shopEdit.phone.$touch()"
                @blur="$v.shopEdit.phone.$touch()"
              />
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.open-day") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0">
              <v-row no-gutters align="center" class="input-class">
                <v-col
                  v-for="(item, i) in active_days"
                  :key="i"
                  cols="3"
                  class="pt-0 pe-0"
                  xs="1"
                >
                  <v-checkbox
                    :label="item.name_day"
                    color="indigo"
                    v-model="item.is_open"
                    hide-details
                    @change="checkDay(i)"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="mt-3 label-class">
              <span class="label"
                >{{ $t("message.open-hour") }} |
                {{ $t("message.close-hour") }}</span
              >
            </v-col>
            <v-col cols="12" md="10" xs="2" class=" mt-3 input-class">
              <v-row no-gutters class="">
                <v-col>
                  <v-dialog
                    ref="dialog"
                    v-model="modal1"
                    :return-value.sync="shopEdit.open_hour"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shopEdit.open_hour"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :error-messages="open_hourErrors"
                        @input="$v.shopEdit.open_hour.$touch()"
                        @blur="$v.shopEdit.open_hour.$touch()"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal1"
                      v-model="shopEdit.open_hour"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal1 = false">
                        {{ $t("message.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(shopEdit.open_hour)"
                      >
                        {{ $t("message.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
               <v-col class="minus-amount">
                <v-icon class="icon-tilde-class">mdi-tilde</v-icon>
              </v-col>
                <v-col>
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="shopEdit.close_hour"
                    persistent
                    width="290px"
                    
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shopEdit.close_hour"
                        prepend-inner-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="shopEdit.close_hour"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        {{ $t("message.cancel") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(shopEdit.close_hour)"
                      >
                        {{ $t("message.ok") }}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.stripe-key") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                v-model="shopEdit.stripe_account_key"
                :error-messages="stripeErrors"
                @input="$v.shopEdit.stripe_account_key.$touch()"
                @blur="$v.shopEdit.stripe_account_key.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.image") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                outlined
                accept="image/*"
                v-model="shopEdit.image_file"
                @change="upload()"
              >
              </v-file-input>
              <v-img :src="shopEdit.image_url" width="100px" height="100px">
                <!-- <template v-slot:placeholder> -->
                  <v-row
                    v-if="isLoadingImg"
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="blue"
                    ></v-progress-circular>
                  </v-row>
                <!-- </template> -->
              </v-img>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.store-image") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                outlined
                class="file-input-class"
                accept="image/*"
                v-model="shopEdit.store_header_images"
                @change="uploadImgByStore"
                type="file"
                ref="myfile"
                id="file-input"
              >
              </v-file-input>
              <v-row
                  v-if="isLoadingImgStore"
                  class="fill-height ma-0"
              >
                  <v-progress-circular
                  indeterminate
                  color="blue"
                  ></v-progress-circular>
              </v-row>
            </v-col>
            <template>
            <v-container class="d-flex justify-end" >
              <v-row class="mb-6" no-gutters justify="start">
                <v-col cols="2"></v-col>
                <v-col v-for="(img, i) in imgByStore" :key="i">
                  <v-icon @click.prevent="deleteImg(img.id, i)">
                    {{ closePage }}
                  </v-icon>
                  <v-img
                    :lazy-src="img.image_url"
                    :src="img.image_url"
                    max-height="150px"
                    max-width="180px"
                    class=""
                    width="150"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
            </template>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.status") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-select
                v-model="shopEdit.is_active"
                :items="status"
                item-text="name"
                item-value="id"
                outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <div class="d-flex flex-row-reverse mt-3 me-2" tile>
            <div class="m-2">
              <v-btn
                type="submit"
                color="primary"
                @click.prevent="updateShop()"
              >
                <div v-if="isLoading" style="margin-right:10px">
                  <v-progress-circular
                    indeterminate
                    color="white"
                    size="11"
                    left
                  ></v-progress-circular>
                </div>
                {{ $t("message.submit-button") }}
              </v-btn>
            </div>
            <div>
              <v-btn color="grey" class="m-2" @click="$router.go(-1)">
                {{ $t("message.back-button") }}
              </v-btn>
            </div>
          </div>
        </form>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers, numeric } from "vuelidate/lib/validators";
import { mdiMagnify, mdiCloseThick } from "@mdi/js";
import Swal from "sweetalert2/dist/sweetalert2.js";
const whiteSpace = helpers.regex('whitespacing', /^\S*$/);

export default {
  name: "EditShop",
  mixins: [validationMixin],
  validations: {
    shopEdit: {
      store_name: {
        required,
      },
      store_address: { required },
      prefecture: { required },
      city: { required },
      district: { required },
      postal_code: { required, maxLength: maxLength(8) },
      images: { required },
      open_hour: { required },
      phone: { numeric: numeric, maxLength: maxLength(12) },
      close_hour: { required },
      selected_active: { required },
      stripe_account_key: {whiteSpace }
    },
  },
  data: () => ({
    closePage: mdiCloseThick,
    status: [
      { id: 1, name: "営業中" },
      { id: 0, name: "閉店" },
    ],
    shopEdit: {
      id: null,
      store_name: "",
      store_address: "",
      prefecture: "",
      city: "",
      district: "",
      phone: "",
      postal_code: "",
      image_url: null,
      image_file: null,
      open_hour: null,
      close_hour: null,
      is_active: null,
      stripe_account_key: "",
      selected_active: [],
      store_header_images: null
    },
    modal2: false,
    isLoadingImgStore: false,
    isLoadingImg: false,
    modal1: false,
    isLoading: false,
    isPostal: true,
    errorMsg: null,
    message: {},
    iconSearch: mdiMagnify,
    active_days: [
      {
        id: 1,
        is_open: 0,
        name_day: "月",
      },
      {
        id: 2,
        is_open: 0,
        name_day: "火",
      },
      {
        id: 3,
        is_open: 0,
        name_day: "水",
      },
      {
        id: 4,
        is_open: 0,
        name_day: "木",
      },
      {
        id: 5,
        is_open: 0,
        name_day: "金",
      },
      {
        id: 6,
        is_open: 0,
        name_day: "土",
      },
      {
        id: 7,
        is_open: 0,
        name_day: "日",
      },
    ],
  }),
  methods: {
    async fetchShopDetail() {
      let shopId = this.$router.history.current.params.id;
      await this.$store.dispatch("shop_module/fetchShopDetail", shopId);

      let shopDetail = await this.$store.state.shop_module.shopDetail;
      this.$store.dispatch("uploadImage_module/setImg", shopDetail.raw_header_images);

      let formatPostalcode = shopDetail.postal_code;
      let convertStr = formatPostalcode.toString();
      let newPostcode = convertStr.slice(0, 3) + "-" + convertStr.slice(3);

      this.shopEdit.id = shopDetail.id;
      this.shopEdit.store_name = shopDetail.store_name;
      this.shopEdit.postal_code = newPostcode;
      this.shopEdit.prefecture = shopDetail.prefecture;
      this.shopEdit.store_address = shopDetail.store_address;
      this.shopEdit.open_hour = shopDetail.open_hour;
      this.shopEdit.close_hour = shopDetail.close_hour;
      this.shopEdit.image_url = shopDetail.image_url;
      this.shopEdit.store_header_images = shopDetail.raw_header_images;
      this.shopEdit.is_active = shopDetail.is_active;
      this.shopEdit.city = shopDetail.city;
      this.shopEdit.district = shopDetail.district;
      this.shopEdit.phone = shopDetail.phone;
      this.shopEdit.stripe_account_key = shopDetail.stripe_account_key;

      //set active day checked
      shopDetail.raw_active_days.forEach((element) => {
        let index_active = this.active_days.findIndex(
          (o) => o.name_day === element.name_day
        );
        // active_day.is_open = 1
        if (index_active > -1) {
          this.active_days[index_active].id = element.id;
          this.active_days[index_active].name_day = element.name_day;
          this.active_days[index_active].is_open = element.is_open;
        }
      });
    },
    async updateShop() {
      this.isLoading = true;
      this.message.title = this.$t("message.title-updateShop-success-message");
      this.$v.$touch();

      //remove id, image_file
      let shopId = this.$router.history.current.params.id;
      delete this.shopEdit.id;
      delete this.shopEdit.image_file;
      delete this.shopEdit.selected_active;

      // add active_days in shop edit
      this.shopEdit.active_days = [];
      this.active_days.forEach((e) => {
        this.shopEdit.active_days.push({
          id: e.id,
          is_open: e.is_open,
        });
      });

      let refacPostcode = this.shopEdit.postal_code;
      refacPostcode = refacPostcode.replace("-", "");
      this.shopEdit.postal_code = refacPostcode;

      /* SET STORE_HEADER_IMAGE BY STATE */
      let setImgByStore = this.$store.state.uploadImage_module.resLink;
      this.shopEdit.store_header_images = setImgByStore;

      let resp = await this.$store.dispatch("shop_module/editShopList", {
        data: this.shopEdit,
        id: shopId,
        message: {
          title: this.message.title,
        },
      });
      return this.$router.push("/shop");
    },
    async uploadImgByStore() {
      this.isLoadingImgStore = true
      if(this.shopEdit.store_header_images != null) {
        await this.$store.dispatch("uploadImage_module/uploadImg", {
          images: this.shopEdit.store_header_images,
          path: "store_image"
        })
      }
      this.isLoadingImgStore = false
    },
    async upload() {
      this.isLoadingImg = true
      if (this.shopEdit.image_file != null) {
        await this.$store.dispatch("uploadImage_module/upload", {
          images: this.shopEdit.image_file,
          path: "itemsV2",
        });

        let uploadImgRes = await this.$store.state.uploadImage_module
          .resLinkImg;
        this.shopEdit.image_url = uploadImgRes.image_url;
      } else {
        this.shopEdit.image_url = null;
      }
      this.isLoadingImg = false
    },
    deleteImg(imageId, i) {
      return this.$store.dispatch("uploadImage_module/deleteImgStore", {
        imgId: imageId,
        item_id: this.$route.params.id,
        imgIndex: i,
      });
    },
    clearItem() {
      this.shopEdit.id = null;
      this.shopEdit.store_name = "";
      this.shopEdit.postal_code = "";
      this.shopEdit.prefecture = "";
      this.shopEdit.store_address = "";
      this.shopEdit.open_hour = null;
      this.shopEdit.close_hour = null;
      this.shopEdit.image_url = "";
      this.shopEdit.is_active = null;
      this.shopEdit.active_days = [];
      this.shopEdit.city = "";
      this.shopEdit.district = "";
    },
    checkDay(index) {
      let current_check = this.active_days[index].is_open;
      if (current_check) {
        this.active_days[index].is_open = 1;
      } else {
        this.active_days[index].is_open = 0;
      }
    },
    async getAddress() {
      let postalcode = this.shopEdit.postal_code;
      postalcode = postalcode.replace("-", "");
      let resp = await this.$store.dispatch("shop_module/getAddressByPostal", {
        postal: postalcode,
      });
      if (resp.data.length > 0) {
        let respaddr = resp.data[0];
        this.shopEdit.prefecture = respaddr.pref;
        this.shopEdit.city = respaddr.city;
        this.shopEdit.district = respaddr.town;
      } else {
        let message;
        if (typeof resp.data.message === "undefined")
          message = this.$t("message.postal-code-notfound-message");
        else message = this.$t("message.postal-code-api-error-message");

        this.shopEdit.prefecture = "";
        this.shopEdit.city = "";
        this.shopEdit.district = "";
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "warning",
          title: message,
        });
      }
    },
  },
  computed: {
    imgByStore() {
      return this.$store.state.uploadImage_module.resLink;
    },
    uploadImg() {
      return this.$store.state.uploadImage_module.resLinkImg;
    },
    stripeErrors() {
      const errors = [];
      if (!this.$v.shopEdit.stripe_account_key.$dirty) return errors;
      !this.$v.shopEdit.stripe_account_key.whiteSpace && errors.push(this.$t('message.err-stripe'));
      return errors;
    },
    storeErrors() {
      const errors = [];
      if (!this.$v.shopEdit.store_name.$dirty) return errors;
      !this.$v.shopEdit.store_name.required &&
        errors.push(this.$t('message.err-store-name'));
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.shopEdit.store_address.$dirty) return errors;
      !this.$v.shopEdit.store_address.required &&
        errors.push(this.$t('message.err-address'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if(!this.$v.shopEdit.phone.$dirty) return errors;
      !this.$v.shopEdit.phone.numeric &&
        errors.push(this.$t("message.err-msg-phone-numeric"));
      !this.$v.shopEdit.phone.maxLength &&
        errors.push(this.$t("message.err-msg-phone-maxLength"));
      return errors;
    },
    prefectureErrors() {
      const errors = [];
      if (!this.$v.shopEdit.prefecture.$dirty) return errors;
      !this.$v.shopEdit.prefecture.required &&
        errors.push(this.$t('message.err-prefecture'));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.shopEdit.city.$dirty) return errors;
      !this.$v.shopEdit.city.required &&
        errors.push(this.$t('message.err-city'));
      return errors;
    },
    districtErrors() {
      const errors = [];
      if (!this.$v.shopEdit.district.$dirty) return errors;
      !this.$v.shopEdit.district.required &&
        errors.push(this.$t('message.err-district'));
      return errors;
    },
    postal_codeErrors() {
      const errors = [];
      this.isPostal = false;
      if (!this.$v.shopEdit.postal_code.$dirty) return errors;
      !this.$v.shopEdit.postal_code.required &&
        errors.push(this.$t('message.err-postal-req'));
      !this.$v.shopEdit.postal_code.maxLength &&
        errors.push(this.$t('message.err-postal-digit'));
      if (errors.length > 0) {
        this.isPostal = true;
      }
      return errors;
    },
    imagesErrors() {
      const errors = [];
      if (!this.$v.shopEdit.images.$dirty) return errors;
      !this.$v.shopEdit.images.required &&
        errors.push(this.$t('message.err-images'));
      return errors;
    },
    open_hourErrors() {
      const errors = [];
      if (!this.$v.shopEdit.open_hour.$dirty) return errors;
      !this.$v.shopEdit.open_hour.required &&
        errors.push(this.$t('message.err-open-hour'));
      return errors;
    },
    close_hourErrors() {
      const errors = [];
      if (!this.$v.shopEdit.close_hour.$dirty) return errors;
      !this.$v.shopEdit.close_hour.required &&
        errors.push(this.$t('message.err-close-hour'));
      return errors;
    },
  },
  created() {
    this.fetchShopDetail();
  },
};
</script>

<style>
.v-input .v-input__control .v-text-field__details {
  /* centering text field filter order */
  /* display: none; */
}
.form-inline {
  justify-content: flex-end;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;

}
.v-select.v-text-field--outlined:not(.v-text-field--single-line)
  .v-select__selections {
  padding: 0px !important;
}
.v-input__append-outer {
  margin-top: 3px !important;
}
.v-input__prepend-inner  {
  margin-top: 3px !important;
}
.v-input__prepend-outer {
  margin-top: 3px !important;
}
</style>
